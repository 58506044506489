import { t } from 'i18next'
import { ReactElement, ReactNode, CSSProperties, RefObject, useCallback, useMemo, useEffect, useRef, useState, DependencyList, FC, memo } from 'react'

import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CircularProgress, Table, TableBody, TableCellProps, TableHead, TableProps, debounce } from '@mui/material'

import { stableSort } from '../../helpers/arrays'
import analyticsService from '../../services/AnalyticsService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { TableSortValues } from '../../types/TableSortValues'
import styles from './GRTable.module.scss'
import { HeaderRow } from './HeaderRow/HeaderRow'
import { SortableRow } from './SortableRow/SortableRow'
import StaticRow from './SortableRow/StaticRow'
import { StatusRow } from './StatusRow/StatusRow'
import { VirtualRow } from './VirtualRow/VirtualRow'
import { getChildColumns, findParentColumn } from './helpers/tableHelpers'
import useVirtualScrollParent from './use-virtual-scroll-parent'

/**
 * Component for representing tabular data. Supports displaying data as more complex components and sorting by columns
 */

type SortAccessor<RowType, CustomPropsType, ColumnIdType> =
  | string
  | (({
      row,
      customTableProps,
      col,
    }: {
      row: RowType
      customTableProps?: CustomPropsType
      col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
    }) => string | number | null)

export type GRTableColumn<RowType, CustomPropsType, ColumnIdType = void> = {
  labelAccessor?:
    | string
    | ReactElement
    | (({
        col,
        handleSort,
        customTableProps,
        columns,
      }: {
        col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
        handleSort?: (column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => void
        customTableProps?: CustomPropsType
        columns?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
      }) => ReactElement | string)
  accessor?: string | (({ row, rowIndex, customTableProps }: { row: RowType; rowIndex: number; customTableProps?: CustomPropsType }) => ReactNode)
  sortable?: boolean | 'manual'
  sortOrder?: SortOrder
  sortAccessor?: SortAccessor<RowType, CustomPropsType, ColumnIdType> | SortAccessor<RowType, CustomPropsType, ColumnIdType>[]
  sortAnalyticsEvent?: {
    eventName: string // name of the event to be sent to analytics (mandatory)
    eventData?: any // extra data to be sent to analytics (optional)
  }
  defaultSortOrder?: SortOrder
  sortFn?: (a: RowType, b: RowType) => number
  width?: number
  cellProps?: TableCellProps
  headerCellProps?: TableCellProps
  hidden?:
    | boolean
    | (({ col, customTableProps }: { col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; customTableProps?: CustomPropsType }) => boolean)
  columns?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  // column id is required only if you need dynamic columns for your table
  id?: ColumnIdType
  // use this prop only if you need to override the column selectors column label retrieved by labelAccessor
  columnSelectorLabelAccessor?:
    | string
    | (({ col, customTableProps }: { col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; customTableProps?: CustomPropsType }) => string)
  // this prop can be used to set the first column of the table sticky
  sticky?: boolean
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type GRTableProps<RowType, CustomPropsType, ColumnIdType = void> = {
  rows: RowType[]
  columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  onColumnsUpdated: (cols: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]) => void
  rowIdKey: string | ((row: RowType, index?: number) => string)
  getRowClass?: (row: RowType, index: number) => string
  getRowStyle?: (row: RowType, index: number) => CSSProperties
  scroller: RefObject<HTMLElement>
  hoverable?: boolean
  striped?: boolean
  tableProps?: TableProps
  noRowsLabel?: ReactNode
  isLoading?: boolean
  defaultSortOrder?: SortOrder
  virtualOverscan?: number
  customProps?: CustomPropsType
  selectedColumns?: ColumnIdType[]
  maxRows?: number
  gridlines?: boolean
  reorder?: boolean
  onReorderRows?: (rows: RowType[]) => void
}

export const Component = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  rows,
  columns,
  hoverable,
  striped,
  tableProps,
  onColumnsUpdated,
  rowIdKey,
  getRowClass,
  getRowStyle,
  scroller,
  noRowsLabel = t('common:no_features_found'),
  isLoading,
  defaultSortOrder = SortOrder.DESC,
  virtualOverscan = 50,
  customProps,
  selectedColumns,
  maxRows,
  gridlines = false,
  reorder = undefined,
  onReorderRows,
}: GRTableProps<RowType, CustomPropsType, ColumnIdType>) => {
  const [activeId, setActiveId] = useState<number | null>(null)

  const handleDragStart = (event: any) => {
    const { active } = event
    setActiveId(active.id)
  }

  // function for handling drag end and reordering
  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (over && active.id !== over.id && onReorderRows) {
      onReorderRows(arrayMove(rows, active.id - 1, over.id - 1))
    }
  }

  // function for accessing individual cell value
  const getCellValue = useCallback(
    ({ row, col, rowIndex }: { row: RowType; col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; rowIndex: number }) => {
      if (col.accessor) {
        if (typeof col.accessor === 'function') {
          return col.accessor({ row, rowIndex, customTableProps: customProps })
        } else if (typeof col.accessor === 'string') {
          return (row as any)[col.accessor]
        }
      }
    },
    [customProps]
  )

  // function for accessing sorting value for a specified column
  const getSortValue = useCallback(
    ({
      row,
      col,
      rowIndex,
      sortAccessor,
    }: {
      row: RowType
      col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
      rowIndex: number
      sortAccessor?: SortAccessor<RowType, CustomPropsType, ColumnIdType>
    }) => {
      if (typeof sortAccessor === 'function') {
        return sortAccessor({ row, customTableProps: customProps, col })
      } else if (typeof sortAccessor === 'string') {
        return (row as any)[sortAccessor]
      } else {
        return getCellValue({ row, col, rowIndex })
      }
    },
    [getCellValue, customProps]
  )

  const getColumnHiddenValue = useCallback(
    (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => {
      // first check if the column is hiden according to column configuration...
      let isHidden = false
      if (typeof col.hidden === 'function') {
        isHidden = col.hidden({ col, customTableProps: customProps })
      } else {
        isHidden = !!col.hidden
      }

      // ...and if not check if it should be hidden according to selected columns
      if (!isHidden && selectedColumns && col.id) {
        return !selectedColumns.includes(col.id)
      } else {
        return isHidden
      }
    },
    [customProps, selectedColumns]
  )

  const { forcedCallback: estimateSize, forceUpdate } = useForcedCallback(() => 100, [])

  // find a given column from current columns
  const findColumn = useCallback(
    (
      columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[],
      column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
    ): GRTableColumn<RowType, CustomPropsType, ColumnIdType> | undefined => {
      const found = columns.find((col) => col === column)
      if (!found && columns.length > 0) {
        return findColumn(
          columns.flatMap((col) => col.columns).filter((childCol) => !!childCol) as GRTableColumn<RowType, CustomPropsType, ColumnIdType>[],
          column
        )
      } else {
        return found
      }
    },
    []
  )

  // handler for clicking headers (sorting)
  const handleHeaderClick = useCallback(
    (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => {
      if (col.sortAnalyticsEvent) {
        analyticsService.trackEvent(col.sortAnalyticsEvent.eventName, {
          data: {
            column: typeof col.labelAccessor === 'string' ? col.labelAccessor : '',
            sortOrder: col.sortOrder,
            gameName: col.sortAnalyticsEvent.eventData?.game.resolvedName,
          },
          serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
        })
      }

      // recursive function for resetting the sort order of all columns except given column
      const resetColumnSort = (
        columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[],
        column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
      ): GRTableColumn<RowType, CustomPropsType, ColumnIdType>[] => {
        if (columns.length > 0) {
          return columns.map((col) => (col !== column ? { ...col, sortOrder: undefined, columns: resetColumnSort(col.columns || [], column) } : column))
        } else {
          return columns
        }
      }

      // find the clicked column and change the sort order + reset sort order for other columns
      const found = findColumn(columns, col)
      if (found) {
        const newSortOrder =
          found.sortOrder === SortOrder.ASC ? SortOrder.DESC : found.sortOrder === SortOrder.DESC ? SortOrder.ASC : found.defaultSortOrder || defaultSortOrder
        found.sortOrder = newSortOrder
        const resetColumns = resetColumnSort(columns, found)

        // report the result to parent
        onColumnsUpdated(resetColumns)
      }
    },
    [columns, onColumnsUpdated, defaultSortOrder, findColumn]
  )

  // sort table rows according to sort order defined in columns
  const tableRows = useMemo(() => {
    let copyOfRows = [...rows]

    // recursively sort rows according to rules defined in columns
    const sort = (columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]) => {
      columns.forEach((col) => {
        if (col.sortable && col.sortOrder) {
          const orderCoefficient = col.sortOrder === SortOrder.ASC ? 1 : -1
          const sorters = Array.isArray(col.sortAccessor) ? col.sortAccessor : [col.sortAccessor]

          // sort the rows by each sorter function or field in column configuration
          sorters.forEach((sorter) => {
            copyOfRows = stableSort(copyOfRows, (a, b) => {
              const valueA = getSortValue({ row: a, col, rowIndex: 0, sortAccessor: sorter })
              const valueB = getSortValue({ row: b, col, rowIndex: 0, sortAccessor: sorter })

              if (valueB === TableSortValues.AlwaysFirst || valueA === TableSortValues.AlwaysLast) {
                return 1
              }

              if (valueA === TableSortValues.AlwaysFirst || valueB === TableSortValues.AlwaysLast) {
                return -1
              }

              // use a custom sort function given for the column
              if (col.sortFn) {
                return col.sortFn(valueA, valueB) * orderCoefficient
              }

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueB.localeCompare(valueA) * orderCoefficient
              }

              if (valueA < valueB) {
                return -1 * orderCoefficient
              }

              if (valueA > valueB) {
                return 1 * orderCoefficient
              }

              return 0
            })
          })
        }

        if (col.columns?.length) {
          sort(col.columns || [])
        }
      })
    }

    sort(columns)

    return maxRows ? copyOfRows.slice(0, maxRows) : copyOfRows
  }, [rows, columns, maxRows, getSortValue])

  const debouncedForcedUpdate = useMemo(() => {
    return debounce(() => {
      forceUpdate()
    }, 300)
  }, [forceUpdate])

  // we will forcefully update the table when rows or columns change
  useEffect(() => {
    debouncedForcedUpdate()
    return () => debouncedForcedUpdate.clear()
  }, [rows, columns, debouncedForcedUpdate])

  // mark width for column based on measuring the headers
  const handleColWidthMeasuring = useCallback(
    (column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>, colWidth: number) => {
      const copyOfColumns = [...columns]
      const foundColumn = findColumn(copyOfColumns, column)
      if (foundColumn && colWidth !== foundColumn?.width) {
        foundColumn.width = colWidth
        onColumnsUpdated(copyOfColumns)
      }
    },
    [columns, onColumnsUpdated, findColumn]
  )

  const rowClass = useCallback((row: RowType, index: number): string => (!!getRowClass ? getRowClass(row, index) : ''), [getRowClass])
  const rowStyle = useCallback((row: RowType, index: number) => (!!getRowStyle ? getRowStyle(row, index) : {}), [getRowStyle])

  const keyExtractor = useCallback(
    (index: number) => {
      const row = rows[index]
      if (typeof rowIdKey === 'string') {
        return (row as any)[rowIdKey]
      } else if (typeof rowIdKey === 'function') {
        return rowIdKey(row, index)
      } else {
        throw new Error('Invalid rowIdKey type')
      }
    },
    [rows, rowIdKey]
  )

  const rowVirtualizer = useVirtualScrollParent({
    size: isLoading ? 0 : tableRows.length,
    parentRef: scroller,
    overscan: virtualOverscan,
    estimateSize: estimateSize,
    keyExtractor: keyExtractor,
  })

  const headRef = useRef<HTMLTableSectionElement>(null)

  // control when to show horizontal scrollbar and adjust container height accordingly
  const virtualContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const showHorizontalScroll = virtualContainerRef.current?.offsetWidth !== tableRef.current?.offsetWidth
  const scrollbarWidth = (virtualContainerRef.current?.offsetHeight || 0) - (virtualContainerRef.current?.clientHeight || 0)

  const totalSize = useMemo(() => {
    return rowVirtualizer.totalSize + (headRef.current?.getBoundingClientRect().height || 0) + scrollbarWidth
  }, [rowVirtualizer.totalSize, scrollbarWidth])

  useEffect(() => {
    // use debounced function to avoid excessive calls on callback
    const onResize = debouncedForcedUpdate
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [debouncedForcedUpdate])

  const visibleColumnCount = useMemo(() => {
    const childColumns = getChildColumns(columns)
    // use current level column if children are empty
    const countableColumns = childColumns.length !== 0 ? childColumns : columns
    return countableColumns.reduce((acc, col) => {
      const parentColumn = findParentColumn(columns, col)
      const isHidden = (parentColumn && getColumnHiddenValue(parentColumn)) || getColumnHiddenValue(col)

      return isHidden ? acc : acc + 1
    }, 0)
  }, [columns, getColumnHiddenValue])

  const dragHandleColumn = {
    headerCellProps: { sx: { width: 60 } },
  }

  return reorder !== undefined ? (
    <div
      ref={virtualContainerRef}
      className={styles['virtual-container']}
      style={{ height: rowVirtualizer.totalSize ? `${totalSize}px` : 'auto', overflowX: showHorizontalScroll ? 'auto' : 'hidden' }}
    >
      <DndContext
        autoScroll={false}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
        onDragStart={handleDragStart}
      >
        <Table ref={tableRef} {...tableProps} className={styles.table} sx={{ width: '100%', tableLayout: 'fixed', height: '100% ' }}>
          <TableHead
            sx={{
              position: 'relative',
            }}
            className={styles.head}
            ref={headRef}
          >
            <HeaderRow
              columns={[dragHandleColumn, ...columns]}
              onHeaderClick={handleHeaderClick}
              onMeasure={handleColWidthMeasuring}
              defaultSortOrder={defaultSortOrder}
              customTableProps={customProps}
              getColumnHiddenValue={getColumnHiddenValue}
              gridlines={gridlines}
            />
          </TableHead>

          <TableBody
            sx={{
              position: 'relative',
            }}
            className={styles.body}
          >
            <SortableContext items={rows.map((row, index) => index + 1)} strategy={verticalListSortingStrategy}>
              {isLoading ? (
                <LoadingStatusRow columnCount={visibleColumnCount} />
              ) : tableRows.length <= 0 ? (
                <StatusRow columnCount={visibleColumnCount}>{noRowsLabel}</StatusRow>
              ) : (
                rowVirtualizer.virtualItems.map((vRow) => {
                  return (
                    <SortableRow
                      key={(vRow.key as string) + vRow.index}
                      id={vRow.index}
                      reorder={reorder}
                      row={tableRows[vRow.index]}
                      virtualRow={vRow}
                      columns={columns}
                      getCellValue={getCellValue}
                      hoverable={hoverable}
                      striped={striped}
                      style={rowStyle(tableRows[vRow.index], vRow.index)}
                      className={rowClass(tableRows[vRow.index], vRow.index)}
                      getColumnHiddenValue={getColumnHiddenValue}
                      gridlines={gridlines}
                    />
                  )
                })
              )}
            </SortableContext>
          </TableBody>
        </Table>

        <DragOverlay dropAnimation={null}>
          {activeId !== null && (
            <Table>
              <TableBody>
                <StaticRow
                  row={tableRows[activeId - 1]}
                  rowIndex={activeId - 1}
                  style={rowStyle(tableRows[activeId - 1], activeId - 1)}
                  columns={columns}
                  getCellValue={getCellValue}
                />
              </TableBody>
            </Table>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  ) : (
    <div
      ref={virtualContainerRef}
      className={styles['virtual-container']}
      style={{ height: rowVirtualizer.totalSize ? `${totalSize}px` : 'auto', overflowX: showHorizontalScroll ? 'auto' : 'hidden' }}
    >
      <Table ref={tableRef} {...tableProps} className={styles.table}>
        <TableHead className={styles.head} ref={headRef}>
          <HeaderRow
            columns={columns}
            onHeaderClick={handleHeaderClick}
            onMeasure={handleColWidthMeasuring}
            defaultSortOrder={defaultSortOrder}
            customTableProps={customProps}
            getColumnHiddenValue={getColumnHiddenValue}
            gridlines={gridlines}
          />
        </TableHead>

        <TableBody className={styles.body}>
          {isLoading ? (
            <LoadingStatusRow columnCount={visibleColumnCount} />
          ) : tableRows.length <= 0 ? (
            <StatusRow columnCount={visibleColumnCount}>{noRowsLabel}</StatusRow>
          ) : (
            rowVirtualizer.virtualItems.map((vRow) => {
              return (
                <VirtualRow
                  key={(vRow.key as string) + vRow.index}
                  row={tableRows[vRow.index]}
                  virtualRow={vRow}
                  columns={columns}
                  getCellValue={getCellValue}
                  hoverable={hoverable}
                  striped={striped}
                  style={rowStyle(tableRows[vRow.index], vRow.index)}
                  className={rowClass(tableRows[vRow.index], vRow.index)}
                  getColumnHiddenValue={getColumnHiddenValue}
                  gridlines={gridlines}
                />
              )
            })
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export const GRTable = memo(Component) as typeof Component

const LoadingStatusRow: FC<{ columnCount: number }> = ({ columnCount }) => (
  <StatusRow columnCount={columnCount}>
    <CircularProgress color="primary" />
  </StatusRow>
)

// Custom hook for handling forced update for a callback function (used to force table re-draw in this case)
const useForcedCallback = (callback: (...args: any[]) => any, deps: DependencyList) => {
  const [value, setValue] = useState(0)
  const forceUpdate = useCallback(() => setValue((val) => val + 1), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forcedCallback = useCallback(callback, [value])

  return { forceUpdate, forcedCallback }
}
